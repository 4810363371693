
import { defineComponent } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import ContentHeader from '@/shared/ContentHeader.vue';

export default defineComponent({
  components: { Banner, BreadcrumbWrapper, ContentWrapper, ContentHeader }
});
