<template>
  <div>
    <banner>
      <template v-slot:image>
        <img src="@/assets/images/pro_visaul2.png" alt="" />
      </template>
      <template v-slot:content>
        <h4>디지털 브라켓 본딩 가이드</h4>
        <img src="@/assets/images/pro_v_tit2.png" alt="" />
      </template>
    </banner>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <content-wrapper :order="1">
      <template v-slot:header>
        <content-header
          title="<strong>정확한 브라켓 포지셔닝 </strong> : 3D S/ W를 활용한 정밀한 위치 제어"
          subTitle="Digital Set-Up 후 브라켓 위치 선정, Initial 치아 브라켓 위치 재연"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <div class="mt50">
          <img src="@/assets/images/pro_img8_1.png" alt="" />
        </div>
      </template>
    </content-wrapper>
    <content-wrapper :order="2">
      <template v-slot:header>
        <content-header title="<strong>체어타임 감소</strong>" subTitle="기존 브라켓 본딩에 비해 시술시간 감소">
        </content-header>
      </template>
      <template v-slot:content>
        <div class="mt40">
          <img src="@/assets/images/pro-img-con-2.png" alt="" />
        </div>
      </template>
    </content-wrapper>
    <content-wrapper :order="3">
      <template v-slot:header>
        <content-header
          title="<strong>재사용 가능</strong>"
          subTitle="브라켓 탈락 시 개별트레이 사용으로 정확한 위치 재연"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <div class="mt40 mb40">
          <div class="txt_area value-con-05">
            <div class="left-box-50">
              <img src="@/assets/images/img-pos-img-03.png" alt="" />
            </div>
            <div class="right-box-50">
              <img src="@/assets/images/img-pos-img-04.png" alt="" />
            </div>
          </div>
        </div>
      </template>
    </content-wrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import ContentHeader from '@/shared/ContentHeader.vue';

export default defineComponent({
  components: { Banner, BreadcrumbWrapper, ContentWrapper, ContentHeader }
});
</script>

<style scoped lang="scss">
@import './MagicPositionerView.scss';
</style>
