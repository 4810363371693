<template>
  <div>
    <p class="t1" v-html="title"></p>
    <p class="t2" v-html="subTitle"></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: String,
    subTitle: String
  }
});
</script>

<style scoped lang="scss">
@import './ContentHeader.scss';
</style>
