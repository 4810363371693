<template>
  <div class="pro_section">
    <div class="pro_container">
      <div class="pro_num" v-if="order">
        <img :src="require(`@/assets/images/t-0${order}.png`)" alt="" />
      </div>
      <div class="pro_inner">
        <div class="pro_title_area">
          <slot name="header"></slot>
        </div>
        <div class="cont_area">
          <slot name="content"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    order: Number
  }
});
</script>

<style scoped lang="scss">
@import './ContentWrapper.scss';
</style>
