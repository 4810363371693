<template>
  <div class="pro_visual visual">
    <div class="bg">
      <slot name="image"> </slot>
    </div>
    <div class="visual_tit">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style scoped lang="scss">
@import './Banner.scss';
</style>
